import { _sCommonController } from '@shein/common-function'
/* sui-mobile prototype init */
// Vue.prototype.$language = {}

// Vue.prototype.$SHEIN = {
//   siteUID: gbCommonInfo.SiteUID,
//   lang: gbCommonInfo.lang,
//   appLanguage: gbCommonInfo.appLanguage,
//   webClient: gbCommonInfo.WEB_CLIENT,
//   isRomwe: gbCommonInfo.IS_RW,
//   cssRight: gbCommonInfo.GB_cssRight
// }
/* sui-mobile*/

/* common-function init */
_sCommonController.registLocals(gbCommonInfo)
/* common-function*/
