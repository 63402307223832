/* globals _GB_LoginEventCenter_ */
import { setCookie, getCookie } from '@shein/common-function'
import { queryString, htmlDecodeCommonFn, getGoodsUrl } from 'public/src/pages/common/utils/index.js'
import { markPoint } from 'public/src/services/mark/index.js'
import './extend'

// 全局变量，弹窗出现时body固定，禁止滚动。但会触发scrollTop(0)事件
window.isBodyFixed = false
// 记录全局弹窗数量
window.bodyFixedCount = 0
// 记录最初弹窗的top值
window.bodyFixedTop = 0

// SHEIN_COMMON 需要回归业务 (目前剩余的这一部分都是 html 模版中会直接用到的方法, 其余部分均已回归业务)
var SHEIN_COMMON = {
  queryString,
  htmlDecode: htmlDecodeCommonFn,
  getGoodsUrl,
  getCookie,
  setCookie,
}

$(() => {
  requestIdleCallback(() => {
    $(document).on('click', '.j-goback', e => {
      let byJump
      const langPath = $('body').attr('lang-path')
      if (e.currentTarget.className.indexOf('j-goback-by-jump') > -1) {
        byJump = true
      }
      if (e.currentTarget.className.indexOf('j-goback-campaign') > -1) {
        setTimeout(function () {
          // 异步执行一些 例如埋点任务
          history.back()
        }, 100)
        return
      }
      if (document.referrer && (document.referrer.indexOf('shein') > -1 || document.referrer.indexOf('romwe') > -1 || document.referrer.indexOf('localhost') > -1)) {
        if (byJump) {
          location.href = document.referrer
        } else {
          if (document.referrer.indexOf('user/login') > -1) {
            location.href = langPath
          } else {
            setTimeout(function () {
              // 异步执行一些 例如埋点任务
              history.back()
            }, 100)
          }
        }
      } else {
        location.href = langPath
      }
    })

    // $(document).on('click', '.j-spa-goback', () => {
    //   const langPath = $('body').attr('lang-path') || '/'
    //   if (document.referrer.indexOf('user/login') > -1) {
    //     location.href = langPath
    //   } else if (location.pathname.indexOf('/shop-multiple-sets-') > -1 && (document.referrer && (document.referrer.indexOf('shein') == -1 || document.referrer.indexOf('romwe') == -1))) { // 站外落地页为shoplook
    //     location.href = langPath
    //   } else {
    //     setTimeout(function () {
    //       // 异步执行一些 例如埋点任务
    //       history.back()
    //     }, 100)
    //   }
    // })
    // window.daEventCenter && window.daEventCenter.addSubscriber({ modulecode: '2-15-1' })
    // $(document).on('click', '.j-index-footer>a', function () {
    //   const index = $('.j-index-footer>a').index(this)
    //   const eleName = ['Shop', 'Category', 'New', 'Bag', 'Me'][index]
    //   // 增加神策埋点 及统一埋点
    //   requestIdleCallback(() => {
    //     window.daEventCenter && window.daEventCenter.triggerNotice({
    //       daId: '2-15-1',
    //       extraData: {
    //         index: index + 1,
    //         tab_name: eleName
    //       }
    //     })
    //   })
    // })

    function _jump (e) {
      const $target = $(e.currentTarget)
      const href = $target.attr('data-href')
      if(!href) return
      // 首次登录成功之后清除keep-alive缓存 修复精投问题
      const shouldReload = _GB_LoginEventCenter_._fristLoginSucc
      let condition = (!shouldReload || href.includes('/cart'))
      if (window._gb_app_ && condition) {
        markPoint('toNextPageClick', 'public')
        if(_gb_app_.$router?.currentRoute.value?.fullPath !== href) {
          if (document.readyState === 'complete') {
            $target.addClass('title-active').siblings().removeClass('title-active')
          }
          _gb_app_.$router.push(href)
        }
      } else {
        if (href.includes('/category')) {
          sessionStorage.setItem('navtrack_to', 'page_category')
          sessionStorage.setItem('navtrack_point', Date.now())
        }
        _GB_LoginEventCenter_._fristLoginSucc = false
        window.location.href = href
      }
    }

    $(document).on('click', '.j-click-shein-logo, .j-header-wishlist, .j-index-tab-list-category, .j-index-tab-list-home, .j-index-tab-list-activity, .j-header-bag, .j-index-tab-list-user', _jump)
    $(document).on('keydown', '.j-click-shein-logo, .j-header-bag, .j-index-tab-list-home, .j-index-tab-list-category, .j-index-tab-list-activity, .j-index-tab-list-user', (e) => { if(e.code === 'Enter' || e.code === 'NumpadEnter') _jump(e) })

    // offline prop
    ;(function () {
      const t = function () {
        if (window.navigator.onLine) {
          $('#offLine').removeClass('on')
        } else {
          if ($('#offLine').length) {
            return $('#offLine').addClass('on')
          }
          setTimeout(function () {
            $('#offLine').addClass('on')
          }, 100)
        }
      }
      t()
      $(window).bind('online offline', function () {
        t()
      })
    })()
  })
})

window.SHEIN_COMMON = SHEIN_COMMON
