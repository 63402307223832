/* globals _GB_PopUpModalEventCenter_, _quickRegisterEventCenter_, _GB_LoginEventCenter_, _eventCenter_*/
const { lang } = gbCommonInfo
import { setCookie, getCookie, getQueryString } from '@shein/common-function'
import { getIpCountrySetCache } from './utils/index.js'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'

// ，根据 from_country 来设置 cookie
const from_country = getQueryString({ key: 'from_country' })
if (['ar', 'ar-en', 'in', 'uk', 'mx', 'hk', 'tw', 'vn', 'se', 'ma', 'maar', 'sg', 'za', 'co'].includes(from_country)) {
  setCookie({ key: 'mobileJump', value: '0' })
  setCookie({ key: `jump_to_m${from_country}`, value: '0', end: 3600 * 24 })
}

const countryData = UserInfoManager.getSync({ key: 'Country', actionType: 'COMMON/IPJUMP' })

var pwaCurrency = UserInfoManager.get({ key: 'currency', options: {onlyCookie: true}, actionType: 'common/ipJump' })

window.gbIsJudgeIP = false

if (!countryData?.countryId || !pwaCurrency) {
  getIpCountrySetCache(function (countryData) {
    var privacy_acountryId = 0
    if (countryData?.countryId) {
      privacy_acountryId = countryData.countryId  
    }

    window.gbIsJudgeIP = true

    // US站欧洲IP需要显示隐私政策
    setTimeout(function () {
      if (typeof _GB_PopUpModalEventCenter_ !== 'undefined') {
        _GB_PopUpModalEventCenter_.emit('us-privacy-ip-welcome', privacy_acountryId)
      }
      if (typeof _quickRegisterEventCenter_ !== 'undefined') {
        _quickRegisterEventCenter_.emit('us-privacy-ip-quickregister', privacy_acountryId)
      }
      if (typeof _GB_LoginEventCenter_ !== 'undefined') {
        _GB_LoginEventCenter_.emit('us-privacy-ip-login', privacy_acountryId)
      }
      if (typeof _eventCenter_ !== 'undefined') {
        _eventCenter_.emit('us-privacy-ip-checkout-login', privacy_acountryId)
      }
    }, 500)
  })
}
