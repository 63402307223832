import TMG from '@shein/time-management-guru'
import { getDetailParamsByUrl } from 'public/src/pages/product_app/store/modules/product_detail/utils/getPrefetchParams.js'

const { tabActive, contextForSSR } = gbCommonInfo || {}
if (tabActive === 'productDetail' && contextForSSR) {
  const params = getDetailParamsByUrl()
  if (params && params.goods_id) {
    let closeEstimatedAndAbPrice = false
    if (typeof window !== 'undefined') {
      closeEstimatedAndAbPrice = !!location.search.includes('landing_page_id') ||
        !!location.search.includes('url_from') ||
        !!location.search.includes('ad_type')
    }
    TMG.triggerQuickRequests('pageGoodsDetail', {
      quickRequestNames: [`getGoodsDetailRealTime/${params.goods_id}`],
      triggerParams: {
        from: 'detail',
        extParams: {
          mallCode: contextForSSR?.modules?.BFF_INFO?.productInfo.selectedMallCode,
          realTimeConfig: {
            specialSceneType: closeEstimatedAndAbPrice ? 1 : 0,
          },
        },
      },

      throttle: true,
    })
  }
}
