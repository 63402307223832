import { asyncLoadFile } from '@shein/common-function'
// import './callApp' // TODO: 待验证是否可以删除
import { createBranchVariable } from './branch.js'
createBranchVariable()

const { PUBLIC_CDN, WEB_CLIENT, SERVER_TYPE } = gbCommonInfo
__webpack_public_path__ = `${PUBLIC_CDN}/pwa_dist/assets/`

function pageOnloadScript() {
  requestIdleCallback(() => {
    // none.css 统计
    appEventCenter.on('pageOnload', () => {
      asyncLoadFile({
        label: 'script',
        attrs: { src: `//count.${WEB_CLIENT}.com/none.css?server=${SERVER_TYPE}` }
      })
    })
  })
}
// 如果在添加事件监听器前 load 事件已经被触发
if (document.readyState === 'complete') {
  setTimeout(pageOnloadScript, 0)
} else {
  window.addEventListener('load', pageOnloadScript)
}
