import { getIsSupportMark, getMarkEngineType, apiWhiteList, jsWhiteList, htmlWhiteList } from 'public/src/services/mark/index.js'
const { WEB_CLIENT, SiteUID, appLanguage, REPORT_URL } = gbCommonInfo
import { getUserAbtData } from '@shein-aidc/basis-abt-router'
import { testTrackerTime } from './testPanelShow.js'

class Detect {
  constructor() {
    this.BRANDS = {
      iPhone: [
        { name: 'iPhone Pro max', width: 390, height: 844 },
        { name: 'iPhone14', width: 390, height: 844 },
        { name: 'iPhone13', width: 390, height: 844 },
        { name: 'iPhone12', width: 390, height: 844 },
        { name: 'iPhone11', width: 414, height: 896 },
        { name: 'iPhone8', width: 375, height: 667 },
      ],
      Samsung: [
        { name: 'SM-S908E', width: 320, height: 640 },
        { name: 'SM-A325F', width: 412, height: 869 },
        { name: 'SM-A515F', width: 412, height: 915 },
        { name: 'SM-A307FN', width: 360, height: 800 },
        { name: 'SM-A217F', width: 360, height: 800 },
      ]
    }
    this.currentDevice = null
  }

  detectDevice() {
    if (this.currentDevice) return this.currentDevice
    const deviceMatches = this.getBrands()
    if (!deviceMatches) return ''
    const screenWidth = window.screen.width
    const screenHeight = window.screen.height
    this.currentDevice = deviceMatches.find(({ width, height }) => {
      return screenWidth === width && screenHeight === height
    })?.name || ''
    return this.currentDevice
  }

  getBrands() {
    const userAgent = navigator.userAgent
    const isiPhone = /iPhone/.test(userAgent)
    const isSamsung = /SM-/.test(userAgent)
    if (!isiPhone && !isSamsung) {
      return ''
    }
    return isiPhone ? this.BRANDS.iPhone : this.BRANDS.Samsung
  }

  detectDeviceLevel() {
    // 0 其他
    // 1  高端
    // 2 中高端
    // 3 中端
    // 4 中低端
    // 5 低端
    const d = this.detectDevice()
    if (!d) return 0

    const map = {
      1: ['iPhone15', 'iPhone14', 'SM-S908E'],
      2: ['iPhone13', 'iPhone12', 'iPhone11', 'SM-A325F', 'SM-A515F'],
      3: ['SM-A307FN'],
      4: ['iPhone8', 'SM-A217F'],
    }

    for (const [key, value] of Object.entries(map)) {
      if (value.includes(d)) return Number(key)
    }
    return 0
  }
}

export class ClientAnalytics {
  static name = 'ClientAnalytics'

  #IFS = ['init', 'registerPage', 'track', 'defineTrack']

  constructor() {
    function CA(_) {
      return function () {
        (CA._q = CA._q || []).push([_, arguments])
      }
    }

    this.#IFS.forEach(_ => CA[_] = CA.call(null, _))
    window['sheinClientAnalytic202110'] = 'clientAnalytics'
    window.clientAnalytics = this.CA = CA

    this.detectHook = new Detect()
  }

  init() {
    return async () => {
      const module = await import('@shein/client-analytics-sdk')
      if (!module || !module.default) return
      this.CA = module.default

      let timer = null
      let count = 0

      // 注:
      // 1. 打点功能在设置 cookie vueDebug=1 时开 SDK 使用 performance.mark 进行打点（自动化测试场景下使用并收集相关数据）
      // 2. 打点功能在设置 cookie performanceTest=1 时, 使用 Date.now 进行打点, 打点信息仅收集不上报, 测试可通过性能页面查看相关数据
      // 3. 常规情况下 SDK 改为使用 Date.now 进行打点，因为 performance.mark 会影响页面性能, 并上报相关数据为后续APM看板分析做准备
      const isSupportMark = getIsSupportMark()
      let markEngineType = getMarkEngineType()
      const { PerformanceLogNodeTestFetch } = await getUserAbtData()
      if (PerformanceLogNodeTestFetch?.param?.PerformanceLogNodeTestFetch === 'YES') {
        markEngineType = 'labRecordVideo'
      }
      
      this.CA.init({
        reportUrl: `${REPORT_URL.SA_REPORT_URL}/clientPerInfo`,
        random: 1,
        performanceTargetCollectConfig: {
          isSupportMark, // 是否支持打点并启用SDK
          isDebug: false,
          markEngineType,
          apiWhiteList,
          jsWhiteList,
          htmlWhiteList,
          callback: function (data) {
            if (markEngineType !== 'labRecordVideo') return
            testTrackerTime.record(data)
          }
        },
      })

      this.CA.registerPage({
        device_type: 'pwa',         // 平台
        sub_site: SiteUID,          // 站点
        language: appLanguage,      // 语言
        home_site: WEB_CLIENT,      // 品牌
        device_model: this.detectHook.detectDevice(), // 设备型号
        device_level: this.detectHook.detectDeviceLevel(), // 设备级别
        page_name: function () {
          return window?.SaPageInfo?.page_name || 'other'
        }
      })

      timer = setInterval(() => {
        count += 1
        // 如果有设置了值或者已经等了5秒了
        if (window?.SaPageInfo?.page_name || count > 5) {
          clearInterval(timer)
          timer = null
          //开启采集
          this.CA.track({
            resource: {
              random: 0.05,
            }
          })
        }
      }, 1000)

      // 仅在拥有实验室标识时异步加载对应入口
      if (markEngineType === 'testDateNow') {
        // 延迟 3s 加载实验室上报入口, 避免影响业务性能
        setTimeout(() => {
          import('public/src/pages/common/laboratory/index.js').then(res => {
            res?.laboratoryController?.insert?.()
          })
        }, 1000 * 3)
      }
    }
  }

  _defineTrack({ data, options = {} }) {
    this.CA.defineTrack(data, options)
  }
}
