import { createApp } from 'vue'
import mitt from 'mitt'

// eslint-disable-next-line no-new
window._GB_SignupEventCenter_ = mitt()
const evt = window._GB_SignupEventCenter_

evt.isLoadingJsModule = false // 是否正在加载登录相关模块
evt.opts = {} // 登录弹窗配置信息

// 多实例对象
let Instances = { default: null }

window.Instances = Instances

class InstanceActions {
  queue = [] // 展示实例队列
  cur = '' // 当前展示实例
  opts = {}
  constructor() {

  }
  getOpts () {
    return this.opts[this.cur] || {}
  }

  setOpts (opts = {}) {
    return this.opts[this.cur] = opts
  }
  /**
   *
   * @param {*} name 实例名字
   * @param {*} opts 实例参数
   */
  push (name, opts) {
    if (name != this.cur) this.queue.push(name)
    this.cur = name
    this.actionCb(opts)
    this.setOpts(opts)
  }

  pop () {
    const name = this.queue.pop()
    this.cur = this.queue[this.queue.length - 1]
    if (this.opts[name]) delete this.opts[name]
  }

  callCb (response) {
    const name = this.cur
    const opts = this.opts[name]
    this.pop()
    opts?.realCb?.(response)
  }
  // 回调函数包装,作用通用事件处理
  actionCb (opts) {
    if (typeof opts.cb !== 'function') return
    opts.realCb = opts.cb
    opts.cb = (response) => {
      this.callCb(response)
    }
  }
}

const instanceActions = new InstanceActions()

evt.showPop = (data) => {
  const opts = typeof data === 'object' ? data : { show: true }
  const instancename = opts.instance || 'default'
  instanceActions.push(instancename, opts)
  // evt.opts = opts

  if (!Instances[instancename]) {
    initLoginPageLoadingModal(opts, instancename)
  } else {
    showInstancePop(opts, instancename)
    if (opts.loaded && typeof opts.loaded === 'function') opts.loaded()
  }
}

function showInstancePop(data, instancename) {
  Instances[instancename].showPop(data)
}

// 创建快速注册模块的加载 HTML
function createLoginHtml () {
  const loadingHtml = document.createElement('div') // loading HTML
  loadingHtml.setAttribute('class', 'j-signup-fast-loading')
  loadingHtml.innerHTML = '<s-loading type="curpage" :show="isShowLoading" />'

  const signupHtml = document.createElement('div') // 快速注册弹窗HTML
  signupHtml.setAttribute('class', 'j-signup-fast')

  return { signupHtml, loadingHtml }
}

// 初始化快速注册模块
function initLoginPageLoadingModal (data, instancename) {
  if (evt.isLoadingJsModule) return

  evt.isLoadingJsModule = true

  const el = createLoginHtml()

  const fragment = document.createDocumentFragment()

  fragment.appendChild(el.loadingHtml)

  document.body.appendChild(fragment)
  // eslint-disable-next-line no-new
  const app = createApp({
    data() {
      return { isShowLoading: false }
    },
    mounted() {
      this.isShowLoading = true
      import(/* webpackChunkName: "signup-fast" */ './index').then((v) => {
        Instances[instancename] = v.default
        evt.isLoadingJsModule = false
        this.isShowLoading = false
        setTimeout(() => {
          showInstancePop(data, instancename)
        }, 1000)
        if (data.loaded && typeof data.loaded === 'function') data.loaded()
      })
    },
  })
  app.mount(el.loadingHtml)
}

window.SHEIN_SIGNUP = {
  instanceActions,
  show: evt.showPop,
  evt,
}
