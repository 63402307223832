/**
 * login模块按需加载模块
 * 该文件在 public/common/index.js 里面引入
 */
import mitt from 'mitt'
import schttp from 'public/src/services/schttp/index'
import { setLocalStorage } from '@shein/common-function'
import { getBenifitImages } from '@login/utils/index.js'

// eslint-disable-next-line no-new
if(!window._GB_LoginEventCenter_){
  window._GB_LoginEventCenter_ = mitt()
}
const evt = window._GB_LoginEventCenter_

evt.isLoadingJsModule = false // 是否正在加载登录相关模块
evt.opts = {} // 登录弹窗配置信息

// 多实例对象
let Instances = { default: null }

class InstanceActions {
  queue = [] // 展示实例队列
  cur = '' // 当前展示实例
  opts = {}
  constructor() {

  }
  getOpts () {
    return this.opts[this.cur] || {}
  }

  setOpts (opts = {}) {
    return this.opts[this.cur] = opts
  }
  /**
   * 
   * @param {*} name 实例名字
   * @param {*} opts 实例参数
   */
  push (name, opts) {
    if (name != this.cur) this.queue.push(name)
    this.cur = name
    this.actionCb(opts)
    this.setOpts(opts)
  }

  pop () {
    const name = this.queue.pop()
    this.cur = this.queue[this.queue.length - 1]
    if (this.opts[name]) delete this.opts[name]
  }

  callCb (response) {
    const name = this.cur
    const opts = this.opts[name]
    this.pop()
    opts?.realCb?.(response)
  }
  // 回调函数包装,作用通用事件处理
  actionCb (opts) {
    if (typeof opts.cb !== 'function') return
    opts.realCb = opts.cb
    opts.cb = async (response) => {
      this.callCb(response)
      const fetchAbtPage  = (await import('public/src/pages/common/abtPageAll')).default
      setTimeout(() => {
        fetchAbtPage?.init?.() // 登录成功后调用abt服务
      }, 100)
    }
  }
}

const instanceActions = new InstanceActions()

/**
 * 对外提供的方法,展示弹窗登录组件
 * @param {object} config
 * @param config.show 是否显示登录弹窗
 * @param config.reload 是否reload页面, 默认会reload页面
 * @param config.to 登录成功跳转页面
 * @param config.cb 回调函数
 * @param config.from 用来区分来源
 * @param config.extra
 * @param config.ga ga埋点场景值
 * @param config.loaded 加载成功回调
 * @param config.ga  ga埋点登录场景区分
 * @param config.bi  自有埋点登录场景区分
 * @param config.sensors  神策埋点登录场景区分
 * @param config.toRobot  是否客服机器人相关按钮唤起登录
 * @param config.instance  实例标识，拉起多个实例的时候需要传这个标识
 * @param config.action  操作行为
 * @param config.action_val  操作参数
 *
 * 例子:
 *
 * showPop({ show: true, reload: false, to: '', cb: () => {})
 */

evt.showPop = (data) => {
  const opts = typeof data === 'object' ? data : { show: true }
  const instancename = opts.instance || 'default'
  instanceActions.push(instancename, opts)
  const isRefresh = window?.isLoginSuccessRefresh
  if(Instances[instancename] && !isRefresh){
    if (opts.show && !opts.activelyPopLogin) {
      const ele = Instances[instancename]?.$refs?.mainPage?.$el
      observeLoginDialogElement(ele)
    }
    showInstancePop(opts, instancename)
    if (opts.loaded && typeof opts.loaded === 'function') opts.loaded()
    return
  }
  window._gb_app_ && _gb_app_?.$store?.commit('assignRootState', { isShowLoginModal: true })
  if(!data?.silence) {
    window._gb_app_ && _gb_app_?.$store?.commit('changeRootSLoadingStatus', { show: true })
  }
  loadLoginComponent(opts, instancename)
}

function showInstancePop(data, instancename) {
  Instances[instancename].showPop(data)
}

function observeLoginDialogElement (ele) {
  try {
    if (typeof gbCommonInfo === 'undefined' || (typeof SaPageInfo === 'undefined' && typeof getSaPageInfo === 'undefined')) {
      return
    }
    window.SHEIN_LOGIN.navtrack_showLoginDialogStart = Date.now()
    const { OBSERVE_LOGIN_DIALOG_FSP_PAGES = [] } = gbCommonInfo
    const pageName = SaPageInfo?.page_name || getSaPageInfo?.page_name
    const needObserve = !OBSERVE_LOGIN_DIALOG_FSP_PAGES?.length || OBSERVE_LOGIN_DIALOG_FSP_PAGES.includes(pageName)
    if (!needObserve) {
      return
    }
    if (typeof window.fspSDK !== 'undefined' && ele) {
      window.fspSDK.observeCustomScene(ele, {
        thresholdLower: -1
      })
    }
  } catch (error) {
    console.log(error)
  }
}


function loadLoginComponent(config, instancename) {
  if (config.show && !config.activelyPopLogin) {
    observeLoginDialogElement('dialog_login')
  }
  const InitPage = async (res) => {
    const { aesKey, LoginLangText, LoginPageAbt, LoginPreloadData } = res
    const { countryName } = LoginPreloadData || ''
    if(countryName){
      setLocalStorage({
        key: 'country_tag_outdated',
        value: countryName,
        expire: 24 * 60 * 60 * 1000,
      })
    }
    window.loginLanguage = LoginLangText || {}
    window.LoginPageAesKey = aesKey

    if (window?.SHEIN_LOGIN) window.SHEIN_LOGIN.__pre_abt = LoginPageAbt
    if (window?.SHEIN_LOGIN) window.SHEIN_LOGIN.__pre_data = LoginPreloadData
    const { prefetchResource } = await import('public/src/services/prefetchResource/index')
    const loadComponent = prefetchResource.importAsyncComponent({
      chunkName: 'login-module', // 跟webpackChunkName保持一致
      componentFactory: () => import( /* webpackChunkName: 'login-module' */ './main_app')
    })
    loadComponent().then((v) => {
      Instances[instancename] = v.default()
      if(!config?.silence){
        window._gb_app_ && _gb_app_?.$store?.commit('changeRootSLoadingStatus', { show: false })
      }
      showInstancePop(config, instancename)
      if (config.loaded && typeof config.loaded === 'function') config.loaded()
      // 利益点图片预加载
      if (LoginPageAbt?.LoginPageIncentive?.p?.benefitShowType == 'on') getBenifitImages()
    })
  }
  doUserAuthInit().then(res => InitPage(res))
}

window.SHEIN_LOGIN = {
  instanceActions,
  show: evt.showPop,
  silence: false,
  evt,
  isShow () {
    return window?.SHEIN_LOGIN?.instanceActions?.opts?.default?.show
  },
  instances: Instances,
}

function doUserAuthInit() {
  return new Promise((resolve) => {
    schttp({ method: 'POST', url: '/api/auth/initData/get' }).then((res) => {
      resolve(res)
    }).catch(() => {
      schttp({ method: 'GET', url: '/api/common/language/get', params: { page: 'login' } }).then((res) => {
        resolve(res)
      })
    })
  })
}
