//货币和多语言纠正

import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
import { setLocalStorage } from '@shein/common-function'
import schttp from '../../services/schttp'
import { getCookie, removeCookie } from '@shein/common-function'

const { lang, SUPPORT_LANGS, currency, currencies={} } = gbCommonInfo
const MAX_AGE = 1000 * 60 * 60 * 24 * 20  //20天

const currencyManager = UserInfoManager.get({ key: 'currency' })
const langManager = UserInfoManager.get({ key: 'language' })

let nowCurrencies = []

try {
  const nowCurInfo = currencies[lang] || {}
  nowCurrencies = Object.keys(nowCurInfo)

  //提供给用增游戏等其它pwa项目获取货币
  setLocalStorage({ key: 'currency', value: currencyManager, expire: MAX_AGE })
} catch(e){ 
  console.log('currency error', e)
  nowCurrencies = []
}

let currencyDisMatch = false
let langDisMatch = false

//如果货币不匹配 
if( gbCommonInfo.currency != currencyManager ){
  gbCommonInfo.currency = currencyManager
  currencyDisMatch = true
}

//如果多语言不匹配 
if( langManager != lang ){
  gbCommonInfo.lang = langManager
  langDisMatch = true 
}


if( langDisMatch || currencyDisMatch ){
  let data = {
    SUPPORT_LANGS, nowCurrencies,
    lang, currency, currencyManager, langManager
  }

  langDisMatch && (data.langDisMatch = 1)
  currencyDisMatch && (data.currencyDisMatch = 1)
  nowCurrencies.includes(currency) && (data.currencyInNowCurrencies = 1)

  schttp({
    method: 'POST',
    url: '/api/common/infoDismatchLog/post',
    data,
  })
}


// /******** 以下是对老用户的cookieid 处理 */
//oest处理老用户的在线cookieId

// function encodeToUnicode(str) {
//   return str.split('').map(char => {
//     let code = char.charCodeAt(0).toString(16);
//     return "\\u" + code.padStart(4, '0'); // 使用 \uXXXX 格式
//   }).join('');
// }

//该编码值由以上encodeToUnicode函数生成
const oldStr = '\\u0063\\u006f\\u006f\\u006b\\u0069\\u0065\\u0049\\u0064'

function decodeFromUnicode(unicodeStr) {
  let str = ''
  try {
    str = unicodeStr.replace(/\\u([\da-f]{4})/gi, (match, code) => {
      return String.fromCharCode(parseInt(code, 16))
    })
  } catch (e){
    console.error('decodeFromUnicode error', e)
  }
  return str
}

const keyStr = decodeFromUnicode(oldStr)
// eslint-disable-next-line 
if( keyStr && getCookie(keyStr) ){
  schttp({
    method: 'POST',
    url: '/api/common/infoOc/post',
  }).then(res => {
    if( res?.code == 0 && res.info?.make == 1 ){
      //复制自setCookie
      var __CurrentMainDomain__ = (function() {
        var hostnameArray = location.hostname.split('.')
        return '.' + hostnameArray.slice(1).join('.')
      })()
      removeCookie({ key: keyStr })
      removeCookie({ key: keyStr, domain: __CurrentMainDomain__ })
    }
  })
}