const { PUBLIC_CDN } = gbCommonInfo
import { preloadImgs } from '@shein/common-function'

export const showInputTips =  (tip, msg, show = true) => {
  tip.show = show
  tip.txt = msg
}


export const formatAccountList = (list) => {
  if (!Array.isArray(list) || list.length < 1) return []
  const filteredArray = list.filter(v => {
    if(v.alias_type == 2){
      return v.encryption_alias && v.desensitize_alias && v.uid && v.area_code && v.area_abbr
    } else {
      return v.encryption_alias && v.desensitize_alias && v.uid 
    }
  })
  return filteredArray
}


export const formatThirdLoginType = (alias_type) => {
  let type = ''
  switch(alias_type) {
    case 3:
      type = 12
      break
    case 4:
      type = 11
      break
    case 6:
      type = 15
      break
    case 7:
      type = 13
      break
    case 8:
      type = 16
      break
    case 9:
      type = 17
      break
    default:
      type = false
      break
  }
  return type
}

export const formatLoginType = (types) => {
  let type = ''
  switch(+types) {
    case 12:
      type = 3
      break
    case 11:
      type = 4
      break
    case 14:
      type = 6
      break
    case 13:
      type = 7
      break
    case 16:
      type = 8
      break
    case 17:
      type = 9
      break
    default:
      type = false
      break
  }
  return type
}

export const benifitImgs = {
  coupon_mask: PUBLIC_CDN + '/pwa_dist/images/login/benifit/coupon_mask-3937196357.png',
  promotion_mask: PUBLIC_CDN + '/pwa_dist/images/login/benifit/promotion_mask-5eb0fba873.png',
  coupon_bg: PUBLIC_CDN + '/pwa_dist/images/login/benifit/coupon_bg-143fcd82a5.png',
  shipping_bg: PUBLIC_CDN + '/pwa_dist/images/login/benifit/shipping_bg-ecce5f06d8.png',
  free_cart: PUBLIC_CDN + '/pwa_dist/images/login/benifit/free_cart-e458159a9a.png',
  save_cart: PUBLIC_CDN + '/pwa_dist/images/login/benifit/save_cart-86b87753d3.png',
  'coupon-item-more': PUBLIC_CDN + '/pwa_dist/images/login/benifit/coupon-item-more-aee5ca76d6.png',
  'coupon-item-off_1': PUBLIC_CDN + '/pwa_dist/images/login/benifit/coupon-item-off_1-51016a5fcd.png',
  'coupon-item-off_2': PUBLIC_CDN + '/pwa_dist/images/login/benifit/coupon-item-off_2-67805ff906.png',
  'coupon-item-off_3': PUBLIC_CDN + '/pwa_dist/images/login/benifit/coupon-item-off_3-7df03cd530.png',
  'coupon-item-shipping_1': PUBLIC_CDN + '/pwa_dist/images/login/benifit/coupon-item-shipping_1-fe5a74a339.png',
  'coupon-item-shipping_2': PUBLIC_CDN + '/pwa_dist/images/login/benifit/coupon-item-shipping_2-b167c15a66.png',
  'coupon-item-shipping_3': PUBLIC_CDN + '/pwa_dist/images/login/benifit/coupon-item-shipping_3-2c5cf71496.png'
}

export const getBenifitImages = () => {
  preloadImgs({
    imgs: Object.values(benifitImgs)
  })
}
