/**
 * 全局隐私政策模块按需加载模块
 */
const evt = window._GB_LoginEventCenter_ || {}
/**
 * 对外提供的方法,展示弹窗登录组件
 * @param {object} config
 * @param config.type 隐私政策类型 / p&c: 1 t%c: 2
 */
function showPop (data) { 
  if (directOpenLink(data)) return
  if (typeof window._GB_GobalPrivayInstance === 'undefined') {
    window._gb_app_ && _gb_app_?.$store?.commit('changeRootSLoadingStatus', { show: true })
    initPageLoadingModal(data)
  } else {
    evt.emit('event-global-privay-page-component', data)
  }
}

function directOpenLink (data) {
  const { IS_RW, langPath } = gbCommonInfo
  const types = {
    privacy: { // 隐私政策
      id: IS_RW ? 488 : 282,
      link: '/Privacy-Security-Policy-a-{0}.html',
    },
    termsAndConditions: { // t &a 政策
      id: 399,
      link: '/Terms-and-Conditions-a-{0}.html',
    },
  }
  const { type } = data
  const { id, link } = types[type] || {}
  if (!id) return false
  if (!link) return false
    
  window.open(langPath + link.replace('{0}', id), '_blank')
  return true

}

// 初始化
function initPageLoadingModal (data) {
  import(/* webpackChunkName: "global-privay-module" */ './index').then(() => {
    evt.isLoadingJsModule = false
    window._gb_app_ && _gb_app_?.$store?.commit('changeRootSLoadingStatus', { show: false })
    setTimeout(() => {
      evt.emit('event-global-privay-page-component', data)
    }, 0)
  })
}

window.SHEIN_GLOBAL_PRIVACY = {
  show: showPop
}
