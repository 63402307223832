import { createApp } from 'vue'
const config = [
  {
    // copy from views/skeleton/product_list.html
    // Autor: Yao Ke
    component: () => import('./ProductListSkeleton.vue'),
  },
  // {
  //   // copy from views/skeleton/product_search_result.html
  //   // Autor: Daes Huang
  //   component: () => import('./SearchReresultSkeleton.vue'),
  // },
  {
    // copy from views/skeleton/preloading.html
    // Autor: Zeno Cai
    component: () => import('./preLoadingSkeleton.vue'),
  }
]

export default () => {

  config.forEach(async ({ component }) => {
    const { default: Skeleton } = await component()
    const instance = createApp(Skeleton)
    const container = document.createElement('div')
    // Object.entries(props).forEach(([key, value]) => {
    //   container.setAttribute(key, value)
    // })
    const vm = instance.mount(container)
    const parent = document.body
    parent.appendChild(vm.$el)
  })
}
